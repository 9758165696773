<script>
  import Logout from "$lib/Logout.svelte"
  import {currentUser} from "../stores/auth.js"
</script>

<div id="globalNav">
  <ul class="flex border-b-2 border-b-gray-50">
    <li class="mr-6 ml-6">
      <a href="/" class="text-blue-500 hover:text-blue-800">Home</a>
    </li>
    {#if $currentUser}
      <li class="mr-6">
	<a href="/my-projects" class="text-blue-500 hover:text-blue-800">My Projects</a>
      </li>
      <li class="mr-6">
	<a href="/new-project" class="text-blue-500 hover:text-blue-800">+ New Project</a>
      </li>
      <li class="absolute right-4">
	<Logout />
      </li>
    {:else}
      <li class="mr-6">
	<a href="/sign-up" class="text-blue-500 hover:text-blue-800">Sign Up</a>
      </li>
      <li class="mr-6">
	<a href="/sign-in" class="text-blue-500 hover:text-blue-800">Sign In</a>
      </li>
    {/if}
  </ul>
</div>
