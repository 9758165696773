<script>
  import {supabase} from "../routes/api/supabase.js"
  import {currentUser} from "../stores/auth.js"
  import {goto} from "$app/navigation";

  const handleLogout = async () => {
    try {
      const {error} = await supabase.auth.signOut()

      if (error) {
	throw error
      }

      goto("/")
    }
    catch (error) {
      console.log('description', error.error_description)
      console.log('message', error.message)
    }
  }
</script>


{#if $currentUser}
<button on:click|preventDefault={handleLogout} class="text-blue-500 hover:text-blue-800">Logout</button>
{/if}
