<script type="module">
  import GlobalNav from "$lib/GlobalNav.svelte"
  import "../styles/tailwind-output.css";
  import { SvelteToast } from '@zerodevx/svelte-toast'

  const toastOptions = {
    duration: 4000,       // duration of progress bar tween to the `next` value
    initial: 1,           // initial progress bar value
    next: 0,              // next progress value
    pausable: false,      // pause progress bar tween on mouse hover
    dismissable: true,    // allow dismiss with close button
    reversed: false,      // insert new toast to bottom of stack
    intro: { x: 256 },    // toast intro fly animation settings
    theme: {}             // css var overrides
  }

</script>

<GlobalNav />

<SvelteToast {toastOptions} />

<div class="container">
  <slot />
</div>

<style>
  .container {
    max-width: 800px;
    margin: 50px auto;
  }
</style>

